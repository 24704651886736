<template>
  <router-view />
</template>

<script>
export default ({
  setup() {
  }
});
</script>
