import firebase from 'firebase/app';
import 'firebase/auth';

import { reactive } from 'vue';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID
};

firebase.initializeApp(firebaseConfig);

export function useAuth() {
  const auth = firebase.auth();

  const user = reactive({
    uid: null,
    name: null,
    surname: null,
    email: null
  });

  /**
   * signIn the user using different type of authentification methods.
   * We are using Firebase auth lib.
   *
   * @param {string} authType The type of singIn used (ex: Google or Email).
   * @param {string} email The email of the user.
   * @param {string} password the password of the user.
   *
   * @@return {boolean} isSignIn return true if the user is sign in.
   */
  const signIn = async (authType, email, password) => {
    let returnedUser = '';
    let isSignIn = false;

    switch (authType) {
      case 'Google': {
        const googleProvider = new firebase.auth.GoogleAuthProvider();
        await auth.signInWithPopup(googleProvider)
          .then((result) => {
            isSignIn = true;
            // The signed-in user info.
            returnedUser = result.user;
            user.uid = returnedUser.uid;
            [user.surname, user.name] = returnedUser.displayName.split(' ');
            user.email = returnedUser.email;
            console.log(user);
          }).catch((error) => {
            // Handle Errors here.
            console.log(error.code, error.message);
            // The email of the user's account used.
            console.log(error.email);
            // The AuthCredential type that was used.
            const credential = googleProvider.credentialFromError(error);
            console.log(credential);
          });
        break;
      }
      case 'Email': {
        await auth.signInWithEmailAndPassword(email, password)
          .then((userCredential) => {
            isSignIn = true;
            // Signed in
            returnedUser = userCredential.user;
            console.log(returnedUser);
          })
          .catch((error) => {
            console.log(error.code, error.message);
          });
        break;
      }
      default: {
        console.error('SignIn is waiting an argument (Google or email).');
      }
    }
    return isSignIn;
  };

  /**
   * signUp the user using his email and password.
   * A validation Email is sent to the user.
   * We are using Firebase auth lib.
   *
   * @param {string} email The email of the user.
   * @param {string} password the password of the user.
   *
   * The function throw error.message if an error occurs.
   */
  const signUp = async (email, password) => {
    await auth.createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Signed UP
        console.log(userCredential.user);
        auth.currentUser.sendEmailVerification()
          .then(() => {
            // Email sent.
            window.alert('A validation email have been sent!');
          })
          .catch((error) => {
            throw error.message;
          });
      })
      .catch((error) => {
        throw error.message;
      });
  };

  /**
   * signOut the user.
   * We are using Firebase auth lib.
   *
   */
  const signOut = () => auth.signOut();

  /**
   * forgotPassword send to the user an email to change his password.
   * We are using Firebase auth lib.
   *
   * @param {string} passwordResetEmail The email of the user.
   *
   * The function display error if an error occurs.
   */
  const forgotPassword = async (passwordResetEmail) => {
    await auth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert(`Password reset email sent to ${passwordResetEmail}, check your inbox.`);
      })
      .catch((error) => {
        window.alert(error);
      });
  };

  return {
    user,
    signIn,
    signUp,
    signOut,
    forgotPassword
  };
}
