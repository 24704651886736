<template>
    <div>
      <h2>Je suis la page home, Bienvenue</h2>
      <button @click="logout">Log out</button>
    </div>
</template>

<script>
import { useAuth } from '@/composable/auth.js';

export default {
  name: 'Home',
  setup() {
    const auth = useAuth();
    const signOut = () => auth.signOut();

    return { logout: signOut };
  },
};
</script>
