import { createRouter, createWebHashHistory } from 'vue-router';
import firebase from 'firebase/app';
import 'firebase/auth';

import Log from '@/views/Log.vue';
import Home from '@/views/Home.vue';

const routes = [
  {
    name: 'Log',
    path: '/',
    redirect: '/login',
    component: Log,
    children: [
      {
        name: 'Register',
        path: '/register',
        meta: { requiresAuth: false },
        component: () => import('@/components/Log/Register.vue'),
      },
      {
        name: 'Login',
        path: '/login',
        meta: { requiresAuth: false },
        component: () => import('@/components/Log/Login.vue'),
      },
    ]
  },
  {
    name: 'Home',
    path: '/home',
    component: Home,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to) => {
  firebase.auth().onAuthStateChanged((user) => {
    if (to.meta.requiresAuth && !user) {
      console.log('I need to login');
      router.replace('/login');
    } else if (to.meta.requiresAuth && user) {
      console.log('Everything is ok I am already connected !');
    } else if (!to.meta.requiresAuth) {
      console.log('Everything is ok I do not need to be connected!');
    }
  });
});

export default router;
