<template>
  <section class="wrapper">
    <div class="content">
      <div class="Login-aside">
        <aside>
          <img
            src="@/assets/login.jpeg"
            alt="login image"
          >
        </aside>
      </div>
      <router-view />
    </div>
  </section>
</template>

<script>
export default {
  name: 'Log',
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Nunito');

$gray-color: #eee;
$input-color: #e4eff8;
$label-color: #999;
$login-button: #2739a1;

body {
  font-family: 'Nunito', sans-serif;
  background: linear-gradient(to right, $background-start, $background-end);
  margin: 0px;
  height: 100vh;
  display: grid;
}

.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    background-color: #48A6FD;
    border-radius: 10px;
    display:flex;
    flex-direction : row;
    align-items: center;
    .Login-aside {
      display: flex;
      align-items: center;
      justify-content: center;
      aside {
        img {
          height: 100%;
          width: 100%;
          max-width: 500px;
          min-width: 300px;
        }
      }
    }
    .Login-box, .Register-box {
      background: white;
      padding: 0px 15px;
      border-radius: 10px;
      width: 100%;
      max-width: 300px;
      min-width: 200px;
      button {
        padding: 15px;
        width: 150px;
        border: 1px solid $gray-color;
        background: transparent;
        border-radius: 50px;
        color: #333;
        cursor: pointer;
        &:focus {
          outline: none;
        }
      }
      header {
        padding-top: 20px;
        text-align: center;
        h1 {
          font-size: 230%;
        }
      }
      section {
        .social-login {
          padding: 10px;
          text-align: center;
          margin-bottom: 20px;
          display: flex;
          justify-content: center;
          button {
            display: flex;
            align-items: center;
            justify-content: center;
            transition: background .5s;
            -webkit-transition: background .5s;
            &:first-child {
              margin-right: 10px;
            }
            &:hover {
              background-color: $gray-color;
            }
            span {
              font-size: 17px;
              color: #777;
              margin-left: 5px;
            }
          }
        }
        .login-form, .register-form {
          padding: 0 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .input-group {
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            width: 100%;
            max-width: 310px;
            label {
              color: $label-color;
              font-size: 12px;
              margin-bottom: 3px;
              margin-left: 16px;
            }
            input {
              padding: 15px;
              font-size: 13px;
              border: 1px solid $input-color;
              background-color: $input-color;
              border-radius: 50px;
              transition: border .5s;
              -webkit-tranistion: border .5s;
              &:focus {
                outline: 0;
                border-color: $background-end;
              }
            }
            button {
              background-color: $login-button;
              border: unset;
              color: white;
              align-self: center;
              margin-top: 15px;
              transition: background .5s;
              -webkit-transition: background .5s;
              &:hover {
                background-color: $background-start;
              }
            }
          }
        }
      }
      footer {
        display: flex;
        justify-content: center;
        padding-top: 15px;
        padding-bottom: 10px;
        text-align: center;
        font-size: 13px;
        a {
          color: $login-button;
          text-decoration: none;
          font-size: 15px;
        }
        .footerText {
          margin-right: 5px;
        }
      }
    }
    a {
        color: $label-color;
        text-decoration: none;
        font-size: 11px;
    }
  }
}

@media screen and (max-width: 720px) {
  .wrapper {
    .content {
      flex-direction: column;
      padding-right: 0px;
      padding-left: 0px;
      margin: 0 10px;
      .Login-aside {
        aside {
          img {
            max-width: 300px;
          }
        }
      }
      .Login-box, .Register-box {
        width: 90%;
        header {
          padding-top: 0px;
          h1 {
            margin-top: 10px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 350px) {
  body {
    background: #48A6FD;
  }
}
</style>
